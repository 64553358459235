import React , { Component } from 'react';
class esign_client_v3 extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <div class="contract-table-outer">

            <div class="contaract-table-page">

                <div class="header">
                    <div class="logo">
                        <a href="https://www.sflworldwide.com/" target="_blank"><img src="https://www.sflworldwide.com/wp-content/uploads/2019/05/logo.png" /></a>
                        <div class="desktop-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                    </div>
                    <div class="header-right">
                        <div class="mobile-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                        contact@sflworldwide.com | www.SFLWorldwide.com<br/>
                        <b>Negotiated Rate Arrangements</b>
                    </div>
                </div>

            
                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '15px'}}>
                        <tr>
                        <td colspan="4" style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Proposal for Overseas Relocation Services (LCL)</td>
                        </tr>
                    </table>
                    <div class="row">
                        <div class="half-col">
                            <table style={{width: '100%'}}>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Customer Name</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Customer_Name$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Origin City:</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$From_Address$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Phone:</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Phone$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Est. Volume</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Est_Volume$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Commodity</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>HHG</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px'}}><b>Service</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Sub_Service$</td>
                                </tr>
                            </table>		
                        </div>
                        <div class="half-col">
                            <table style={{width: '100%'}}>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Quote Ref.:</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Tracking_No$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Delivery City:</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$To_Address$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Email Id:</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}><a href="#">$Email_Id$</a></td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Tracking No.</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$Tracking_No$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Mode</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$ShipmentType$</td>
                                </tr>
                                <tr>
                                <td style={{border: '1px solid #000', width: '40%', padding: '5px', textAlign: 'right'}} class="mobile-align-left"><b>Sales Rep.</b></td>
                                <td style={{border: '1px solid #000', width: '60%', padding: '5px'}}>$ManageBY$</td>
                                </tr>
                            </table>	
                        </div>
                    </div>
                    
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                            <td colspan="2" style={{fontWeight: 'bold',padding: '5px', border: '1px solid #000', background: '#ffc000'}}>DESCRIPTION OF SERVICES</td>
                            <td style={{fontWeight: 'bold',padding: '5px', border: '1px solid #000', background: '#ffc000', textAlign: 'right',width: '15%'}} class="mobile-wd-30">COST (IN USD)</td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', width: '20px', padding: '5px', textAlign: 'center'}}>1.</td>	
                            <td style={{border: '1px solid #000', padding: '5px'}}>Estimate cost for 100CFT by Sea to Mohali, India</td>	
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right'}}>$ 500.00</td>	
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', width: '20px', padding: '5px', textAlign: 'center'}}>2.</td>	
                            <td style={{border: '1px solid #000', padding: '5px'}}>Additional CFT up to 150CFT</td>	
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right'}}>$ 500.00</td>	
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', width: '20px', padding: '5px', textAlign: 'center'}}>3.</td>	
                            <td style={{border: '1px solid #000', padding: '5px'}}>Additional CFT up to 150CFT</td>	
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right'}}>$ 500.00</td>	
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', width: '20px', padding: '5px', textAlign: 'center'}}>4.</td>	
                            <td style={{border: '1px solid #000', padding: '5px'}}>Additional CFT up to 150CFT</td>	
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right'}}>$ 500.00</td>	
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', width: '20px', padding: '5px', textAlign: 'center'}}>5.</td>	
                            <td style={{border: '1px solid #000', padding: '5px'}}>Additional CFT up to 150CFT</td>	
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right'}}>$ 500.00</td>	
                        </tr>
                        <tr>
                            <td colspan="2" style={{fontWeight: 'bold', padding: '5px', textAlign: 'right', border: '1px solid #000', background: '#ffc000'}}>TOTAL</td>
                            <td style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', textAlign: 'right', width: '15%'}}>$ 5000.00</td>
                        </tr>
                    </table>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                        <td colspan="2" style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Best Price <span style={{borderBottom: '1px solid #fff'}}>Guaranteed</span>. We Will Meet or Beat the Best Door to Door Rates!</td>
                        </tr>          
                        <tr>
                            <td style={{fontWeight: 'bold' ,  padding: '5px', border: '1px solid #000', background: '#ffc000', width: '50%'}}>Inclusions</td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', border: '1px solid #000'}}>
                                <ul class="icon-list">
                                    <li>
                                        Door pickup & Transportation to CFS, Palletization if required; Ocean Freight, Door Delivery & Unloading up to 2nd and above with elevator access. 
                                    </li>
                                    <li>
                                        Storage at origin or destination for up to 1 month.
                                    </li>
                                    <li>
                                        Basic export custom clearance at origin.
                                    </li>
                                    <li>
                                        Movers Liability Coverage. 
                                    </li>
                                    <li>
                                        Preparation of overseas shipping documentations
                                    </li>
                                    <li>
                                        Indian customs filling and documentations
                                    </li>
                                    <li>
                                        De-stuffing, opening and repacking during customs inspections, single strapping on each box after customs examinations and loading, concur charges
                                    </li>
                                </ul>
                            </td>	
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', width: '50%'}}>Exclusions (If Applicable)</td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', border: '1px solid #000'}}>
                                <ul class="icon-list">
                                    <li>
                                        Overweight/Oversize charges. Abnormal access, Parking Permits, Stair carry, long carry, Split pickup/Delivery charges.
                                    </li>
                                    <li>
                                        Port Charges, Union Charges and D.O. Charges at Destination.
                                    </li>
                                    <li>
                                        Custom Duties, Taxes and Octroi Charges at Destination
                                    </li>
                                    <li>
                                        Demurrage and Detention charges
                                    </li>
                                    <li>
                                        Standard Unpacking & Removal of Packing debris. 
                                    </li>
                                    <li>
                                        Crating Charges. Handling of heavy weight and odd items like Piano, Pool table, Safe etc. 
                                    </li>
                                    <li>
                                        Palletizing is done for the protection of your cargo and adds 20% to 30% the volume of your shipment, please factor this in your calculation, cannot be estimated until actual measurement is done. Palletizing fees at $45.00 per pallet
                                    </li>
                                    <li>
                                        Packing (Labor & Material) Charges. 
                                    </li>
                                    <li>
                                        AES Filling Charge of USD 75 for Shipment over USD 2500
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="footer">
                    <div class="f-box">
                        <span>Initial Here: $Customer_Signature$</span>
                    </div>
                    <div class="f-box-middle">
                        <b>SFL WORLDWIDE LLC | FMC License No.: 025184</b>
                    </div>
                    <div class="f-box right">
                        <span>Page <b>1</b> of <b>4</b></span>
                    </div>
                </div>

            </div>

            <div class="contaract-table-page page">

            

                <div class="header">
                    <div class="logo">
                        <a href="https://www.sflworldwide.com/" target="_blank">
                            <img src="https://www.sflworldwide.com/wp-content/uploads/2019/05/logo.png" />
                        </a>
                        <div class="desktop-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br />
                            Phone: 972-255-7447 Fax: 877-741-3134<br />
                        </div>
                    </div>
                    <div class="header-right">
                        <div class="mobile-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                        contact@sflworldwide.com | www.SFLWorldwide.com<br/>
                        <b>Negotiated Rate Arrangements</b>
                    </div>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                        <td colspan="2" style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Moving Insurance options</td>
                        </tr>
                        <tr>
                            <td colspan="2" style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', textAlign: 'left'}}>Movers Liability Coverage</td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '80%'}}>
                                All licensed movers have a standard liability of 60 cents per pound per article. For example, if a 50–pound article is damaged as a result of a move, the mover is required to reimburse you $30 (50 lbs. X 0.60 = $30). You can obtain additional insurance to protect your items.
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right', width: '20%'}}>
                                <span class="nowrap">Premium:  <b>$ 0</b></span><br/>
                                <span class="nowrap">Deductible: <b>$ 0</b></span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', textAlign: 'left'}}>Selective Insurance Coverage</td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '80%'}}>
                                Under Selective Risk Insurance shipper can insurance selective item(s) and does not need to take insurance on entire shipment. Your selective item(s) is covered against risks such as breakage, damage, theft, fire, earthquake, collision, accidents, etc. Shipment packed by owner is not covered under Selective Risk Insurance. If the shipment is Packed by owner and required repacking at our warehouse; selective risk insurance will be activated from SFL Worldwide warehouse once items are inspected and repacked professionally. Shipment will be covered at current value and does not cover shipping charges, packing charges, customs duty etc. It is shipper responsibility to review and accept terms and condition offered under Selective Insurance or selects any other insurance provider if needed.
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right',width: '20%'}}>
                                <span class="nowrap">Premium:  <b>5 %</b></span><br/>
                                <span class="nowrap">Deductible: <b>$ 250</b></span><br/>
                                <span class="nowrap">Minimum: <b>$ 50</b></span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', textAlign: 'left'}}>All Risk Insurance Coverage </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '80%'}}>
                                Your shipment is covered against risks such as breakage, damage, theft, fire, earthquake, collision, accidents, etc. Shipment packed by owner is not covered under All Risk Insurance. If the shipment is Packed by owner and required repacking at our warehouse; all risk insurance will be activated from SFL Worldwide warehouse once items are inspected and repacked professionally. Shipment will be covered at current value and does not cover shipping charges, packing charges, customs duty etc. It is shipper responsibility to review and accept terms and condition offered by the insurance company or selects any other insurance provider if needed.
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'right',width: '20%'}}>
                                <span class="nowrap">Premium:  <b>3 %</b></span><br/>
                                <span class="nowrap">Deductible: <b>$ 1000</b></span><br/>
                                <span class="nowrap">Minimum: <b>$300</b></span>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                        <td colspan="2" style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Documentation Requirement</td>
                        </tr>          
                        <tr>
                            <td style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', width: '50%'}}>Foreign Passport Holder(s)</td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', border: '1px solid #000'}}>
                                <ul class="icon-list">
                                    <li>
                                        Scanned copy of passport photo page only (not the entire passport) 
                                    </li>
                                    <li>
                                        Copy of Visa / OCI / PIO card 
                                    </li>
                                    <li>
                                        Copy of EIN number / Authorization to us to apply for and obtain EIN on your behalf 
                                    </li>
                                    <li>
                                        Signatures on export forms that will be provided by us
                                    </li>
                                </ul>
                            </td>	
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', width: '50%'}}>Indian Passport Holder(s)</td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', border: '1px solid #000'}}>
                                <ul class="icon-list">
                                    <li>
                                        Scanned copy of passport photo page (not entire passport)
                                    </li>
                                    <li>
                                        Signatures on export forms that will be provided by us
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontWeight: 'bold', padding: '5px', border: '1px solid #000', background: '#ffc000', width: '50%'}}>Documents Required at Destination</td>
                        </tr>
                        <tr>
                            <td style={{width: '50%',  border: '1px solid #000'}}>
                                <ul class="icon-list">
                                    <li>
                                        Original Passport
                                    </li>
                                    <li>
                                        OCI / PIO Card, if holding foreign passport
                                    </li>
                                    <li>
                                        Signature on Customs forms that will be provided by us
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="footer">
                    <div class="f-box">
                        <span>Initial Here: $Customer_Signature$</span>
                    </div>
                    <div class="f-box-middle">
                        <b>SFL WORLDWIDE LLC | FMC License No.: 025184</b>
                    </div>
                    <div class="f-box right">
                        <span>Page <b>2</b> of <b>4</b></span>
                    </div>
                </div>

            </div>

            <div class="contaract-table-page page">

            
                <div class="header">
                    <div class="logo">
                        <a href="https://www.sflworldwide.com/" target="_blank"><img src="https://www.sflworldwide.com/wp-content/uploads/2019/05/logo.png" /></a>
                        <div class="desktop-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                    </div>
                    <div class="header-right">
                        <div class="mobile-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                        contact@sflworldwide.com | www.SFLWorldwide.com<br/>
                        <b>Negotiated Rate Arrangements</b>
                    </div>
                </div>

                

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                        <td style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Terms & Conditions</td>
                        </tr>
                        <tr>
                            <td style={{padding: '5px', border: '1px solid #002060'}}>
                                <b>
                                    Shipper's presence in India when the shipment reaches is mandatory. If the shipper has not arrived into India or has to travel out again when the shipment reaches, we cannot clear it and additional charges may incur. We would need Original Passport along with Customer's Signatures on Custom Documentation which will be provided by SFL Worldwide as without this document we won't able to clear shipment from customs and additional charges will incur which SFL Worldwide will not be liable for
                                </b><br/><br/>
                                <b>Transit Times:</b> Standard transit time would be 3 to 4 Months unforeseen any delays outside our control. SFL Worldwide would not be able to control any delays on account of the shipping lines as they do not fully disclose to us the reasons for the delayed arrival. These can typically be attributed to adverse weather conditions, port congestion, and vessel maintenance or in some instances delayed departure at the origin. SFL Worldwide will not be responsible for any delays caused due to customs clearance procedure.<br/><br/>
                                <b>Our Liability:</b> SFL Worldwide is only liable for loss of entire package or article that is expressly stated in the household goods descriptive inventory and may occur while your shipment is in our physical possession. Our maximum liability for such loss is limited to an amount not exceeding $0.60 per pound package. For items Packed by owner, SFL Worldwide will not be liable for any damage or missing content inside any package or article and our liability only covers if entire box/package is missing. We are not liable for loss or damage that may occur after the shipment leaves our physical possession.<br/><br/>
                                An optional extended protection plan for your household goods, personal effects and automobiles moving by land, sea and/or air is available subject to your application, acceptance, payment of premiums and compliance with the terms and conditions of our 3rd party insurance underwriter at least 4 days prior to the pickup of your shipment. SFL Worldwide, LLC does recommend that you purchase an extended protection plan for your shipment.<br/><br/>
                                If the shipment arrives at the warehouse in damaged condition, we can either return shipment back to the shipper or dispose shipment at written confirmation from the shipper. Depending upon the option selected to and from shipping charges and disposal fees will be applied. SFL Worldwide is not liable for any damage of loss of the shipment arrived in damaged conditional at SFL or its authorized receiving center. If your shipment does not arrive within 3 weeks from pickup date to our warehouse or we are not able to trace the part or full shipment, SFL Worldwide will compensate under Movers Liability Coverage. <br/><br/>
                                <b>Quote Validity:</b> We appreciate and thank you for the opportunity to submit this proposal for international relocation services. The scope of services to be performed by SFL Worldwide and its agents are limited to the performance of only those services expressly stated in this proposal. Please note, that our services are entirely flexible and can be adapted to your changing requirements. The prices stated herein are valid for a period of Thirty (30) days from the date of this proposal.<br/><br/>
                                <b>Payment Terms:</b> All charges, as above must be paid by check or wire transfer within seven days from the receipt of our trade invoice after pickup of your shipment. Credit Card will be only accepted for payment under $500.00 and credit card fees will be charged at 3% if payment is being made by Credit Card. If payment is not made by due date late fees of $35.00 and interest of 14.99 % per annum will be applied.<br/><br/>
                                <b>Weight Limit:</b> You are allowed to keep the weight equal to the dimensional weight of the box. For Example: If you are using a box with dimension 18x18x24 Inches, so the dimensional weight of this box is 56lbs therefore you can fill this box up to 56 lbs. Here if the actual weight exceeds the allowed limit you will be charged as $2/lb. for additional Lbs. If any of your box is more than 50lbs then there will be additional Charge of $25.00 for that particular box for Over Weight Charges.<br/><br/>
                                Overseas shipping entails handling of cargo multiple times and therefore standard boxes do not have the quality or cardboard strength to sustain all the handling. You must buy Double Walled or Heavy-Duty boxes to ship your belongings which is easily available from Home Depot or Lowes Stores. If boxes are received in damaged condition to our warehouse additional $ 15.00 repacking charges per box will be applied to repack damaged boxes. 
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="footer">
                    <div class="f-box">
                        <span>Initial Here: $Customer_Signature$</span>
                    </div>
                    <div class="f-box-middle">
                        <b>SFL WORLDWIDE LLC | FMC License No.: 025184</b>
                    </div>
                    <div class="f-box right">
                        <span>Page <b>3</b> of <b>4</b></span>
                    </div>
                </div>

            </div>

            <div class="contaract-table-page page">

            
                <div class="header">
                    <div class="logo">
                        <a href="https://www.sflworldwide.com/" target="_blank"><img src="https://www.sflworldwide.com/wp-content/uploads/2019/05/logo.png" /></a>
                        <div class="desktop-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                    </div>
                    <div class="header-right">
                        <div class="mobile-none">
                            3364 Garden Brook Drive, Farmers Branch, Texas – 75234<br/>
                            Phone: 972-255-7447 Fax: 877-741-3134<br/>
                        </div>
                        contact@sflworldwide.com | www.SFLWorldwide.com<br/>
                        <b>Negotiated Rate Arrangements</b>
                    </div>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                            <td colspan="2" style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Transfer of Residence Questionnaire</td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Are you Moving back to India?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Your Name as per passport?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                Purveen Deepak Shah
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                How many years stayed outside India?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                5+
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Stayed in India for more than 6 months in last 2 years?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Have you applied for TR in last 3 years?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Will Provide Original Passport for Custom Clearance?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Your latest arrival date in India?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                01/20/2019
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Arriving in India with?
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                Indian passport
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px', width: '50%'}}>
                                Validity Date
                            </td>
                            <td style={{border: '1px solid #000', padding: '5px', textAlign: 'left', width: '50%'}}>
                                09/24/2024
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%', marginTop: '20px'}}>
                        <tr>
                        <td style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>Your rights and responsibilities</td>
                        </tr>
                        <tr>
                            <td style={{padding: '5px', border: '1px solid #002060'}}>
                                <ul class="icon-list">
                                    <li>
                                        Our House Bill of Lading is the title to your cargo.
                                    </li>
                                    <li>
                                        By signing this contract, you agree that our Household Goods Descriptive Inventory is the official document that contains the pickup date, delivery date, quantity and description of your packages shipped and received.
                                    </li>
                                    <li>
                                        You agree to denote damages to items during packing or loading at the origin in our Household Goods Descriptive Inventory prior to signing and dating at the origin.
                                    </li>
                                    <li>
                                        You agree to denote any missing or damaged packages along with your signature upon delivery of your shipment in our Household Goods Descriptive Inventory prior to signing and dating at the destination.
                                    </li>
                                    <li>
                                        In the event that your shipment is not delivered in whole, you agree to submit your claim in writing to us within 120 days after pickup. We will attempt to locate your shipment within 4 weeks of receiving your written claim. If we are unable to locate your shipment in four weeks and if you have purchased an extended protection plan with us, we will file a claim with the insurance company during the fifth week. If you have not purchased an extended protection plan or if your claim is denied by the insurance company you will be compensated per Movers Liability stated above.
                                    </li>
                                    <li>
                                        In the event that your shipment is delivered in part or in the case of any damages, you agree to submit your claim in writing to us within 7 days after delivery. You agree to denote the missing / damaged packages in our Household Goods Descriptive Inventory during delivery. Upon receipt of your written claim and if you have purchased an All-Risk insurance extended protection plan with us, we will file a claim with the insurance company. If you have not purchased All Risk Insurance or if your insurance claim is denied by the insurance company you will be compensated per Movers Liability stated above.
                                    </li>
                                    <li>
                                        You may address your claim to SFL Worldwide at contact@sflworldwide.com or By Faxing at 1-888-609-0778
                                    </li>
                                    <li>
                                        Please confirm acceptance of this proposal by signing and returning this proposal along with booking deposit USD 250.00 so that we can lock the rates and process your booking. Balance Payment is due in full upon receipt of our Invoice which will be sent after pickup of your shipment.
                                    </li>
                                    <li>
                                        Service provided pursuant to this NVOCC Negotiated Rate Arrangement (NRA) is subject to Carrier's governing rules tariff, which is accessible at www.dpiusa.com in compliance with FMC Regulations as provided in 46 CFR 532.7.”
                                    </li>
                                    <li>
                                        Any legal disputes will be subject to the jurisdiction of Texas State Law where SFL Worldwide LLC is registered.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="table-responsive">
                    <table style={{width: '100%'}}>
                        <tr>
                            <td style={{padding: '15px 5px 15px 5px', border: '1px solid #002060', textAlign: 'center'}}>
                                <b>SFL Worldwide LLC</b><br/><br/>
                                3364 Garden Brook Drive,<br/>
                                Farmers Branch, TX - 75234<br/>
                                Website: www.SFLWorldwide.com | Phone: 1-800-691-2335
                            </td>
                        </tr>
                        <tr>
                        <td style={{border: '1px solid #000', textAlign: 'center', padding: '5px', fontSize: '13px', fontWeight: 'bold', color: '#fff', background: '#002060'}}>
                            Signature Confirmation <i style={{fontWeight: 'normal'}}>(Please sign when ready to confirm):</i>
                        </td>
                        </tr>
                        
                    </table>
                </div>

                <div class="signature-box">
                    <div class="sign">
                        <canvas width="230" height="80" style={{touchAction: 'none', border: '2px solid #F44336'}}></canvas>
                        <span>Customer Signature</span>
                    </div>
                    <div class="date">
                        <p>01/06/2020</p>
                        <span>Date</span>
                    </div>
                    <div class="pr-user">
                        <p>Saumin Shah</p>
                        <span>Working on Proposal</span>
                    </div>
                </div>

                <div class="footer">
                    <div class="f-box">
                        <span>Initial Here: $Customer_Signature$</span>
                    </div>
                    <div class="f-box-middle">
                        <b>SFL WORLDWIDE LLC | FMC License No.: 025184</b>
                    </div>
                    <div class="f-box right">
                        <span>Page <b>4</b> of <b>4</b></span>
                    </div>
                </div>

            </div>

  	    </div>
         );
    }
}
 
export default esign_client_v3;