//const apiBase = "http://192.168.1.91:3000/";
//const apiBase = "http://localhost:4000/";
// const apiBase = "https://phpstack-773983-2633738.cloudwaysapps.com/"; //cloud Production
// const apiBase = "https://hubuatapi.sflworldwide.com/"; //cloud Production
// const apiBase = "https://uatapi.sflworldwide.com:3434/"; // UAT
// const apiBase = 'https://qaapi.sflworldwide.com:4999/';
// const apiBase = 'http://162.241.120.118:9876/';
// const apiBase = 'http://192.168.1.67:3000/';
// const apiBase = "https://hubapi.sflworldwide.com:4443/"; //Production
// const apiBase = 'http://162.241.175.139:3000/';
// const apiBase = 'http://149.28.250.33:3000/';
// const apiBase = 'http://107.180.91.201:3001/' // uiInstance
// const apiBase = 'http://server.evalueadd.com:9696/'; // Demo
const apiBase = "https://hubapi.sflworldwide.com/"; // Production
const fileBase = "https://docs.sflworldwide.com/document/";
//const apiBase = "http://phpstack-773983-2633738.cloudwaysapps.com:4443/";
export { apiBase, fileBase };
